import React from 'react'

const NotFound = () => {
  return (
    <div className='page-view'>
      <h2>404 resource not found</h2>
    </div>
  )
}

export default NotFound