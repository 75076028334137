import React, { Component } from "react"

import api from "../api"

class UpdateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: { 
        "firstname": "", 
        "lastname": "", 
        "email": ""
      },
      fields: { 
        "email": "", 
        "confirm email": "",
        "current password": "", 
        "new password": "",
        "confirm password": ""
      },
      errors: {
        "email": "", 
        "confirm email": "",
        "current password": "", 
        "new password": "",
        "confirm password": "",
        "form":""
      },
    };
  }

  validateEmail = async () => {
    let email = this.state.fields["email"];
    if (!email) {
      let error = "Field cannot be empty";
      this.setError("email", error);
      return false;
    }

    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      let error = "Please enter a valid email";
      this.setError("email", error);
      return false;
    }

    let res = await api.getAccountByEmail(email);
      if(res.data.success) {
        let error = "Email is already in use";
        this.setError("email", error);
        return false;
      } else {
        this.setError("email", "");
        return true;
      }
  }

  validateConfirmationEmail = async () => {
    let valid = true;
    let email = this.state.fields["email"];
    let conf = this.state.fields["confirm email"];
    if (!conf) {
      valid = false;
      let error = "Field cannot be empty";
      this.setError("confirm email", error);
    }

    if (conf !== "") {
      if (conf !== email) {
        valid = false;
        let error = "Emails do not match";
        this.setError("confirm email", error);
      }
    }
    if (valid) {
      this.setError("confirm email", "");
    }
    return valid;
  }

  emailUpdated = async (e) => {
    let email = this.state.fields["email"];
    let conf = this.state.fields["confirm email"];
    if (!email && !conf) {
      return true;
    } else {
      let res = await Promise.allSettled([
        this.validateEmail(),
        this.validateConfirmationEmail()
      ]);
      let valid = true;
      res.forEach( e => {valid = valid && e.value});
      return valid;
    }
  }

  validateCurrentPassword = async () => {
    let originalPassword = this.state.fields["current password"];
    if (!originalPassword) {
      let error = "Field cannot be empty";
      this.setError("current password", error);
      return false;
    }
    let res = await api.verifyPassword({password: originalPassword});
      if (res.data.success) {
        this.setError("current password", "");
        return true;
      } else {
        this.setError("current password", "Password does not match current password");
        return false;
      }
  }

  validateNewPassword = async () => {
    let password = this.state.fields["new password"];
    if (!password) {
      let error = "Field cannot be empty";
      this.setError("new password", error);
      return false;
    }
    if (password.length < 8) {
      let error = "Password must contain a minimum of 8 characters";
      this.setError("new password", error);
      return false;
    }
    if (!password.match(/^[A-Za-z\d@$!%*#?&]{8,}$/)) {
      let error = "Password may only contain numbers, letters and special characters";
      this.setError("new password", error);
      return false;
    }
    if (!password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/)) {
      let error = "Password must contain at least 1 number and letter";
      this.setError("new password", error);
      return false;
    }

    this.setError("new password", "");
    return true;
  }

  validateConfirmationPassword = async () => {
    let valid = true;
    let password = this.state.fields["new password"];
    let conf = this.state.fields["confirm password"];
    if (!conf) {
      valid = false;
      let error = "Field cannot be empty";
      this.setError("confirm password", error);
    }

    if (conf !== "") {
      if (conf !== password) {
        valid = false;
        let error = "Passwords do not match";
        this.setError("confirm password", error);
      }
    }
    if (valid) {
      this.setError("confirm password", "");
    }
    return valid;
  }

  passwordUpdated = async (e) => {
    let newPassword = this.state.fields["new password"];
    let confPassword = this.state.fields["confirm password"];
    if (!newPassword && !confPassword) {
      return true;
    } else {
      let res = await Promise.allSettled([
        this.validateNewPassword(),
        this.validateConfirmationPassword()
      ]);
      let valid = true;
      res.forEach( e => {valid = valid && e.value});
      return valid;
    }
  }

  validateSubmition = async (e) => {
    let valid = false;
    for (let field in this.state.fields) {
      if (field === "current password") {
        continue;
      }
      valid = valid || (this.state.fields[field] !== "");
    }
    if(!valid) {
      let error = "Please update at least one field";
      this.setError("form", error);
      return valid;
    }
    this.setError("form", "");

    let res = await Promise.allSettled([
      this.validateCurrentPassword(),
      this.emailUpdated(),
      this.passwordUpdated()
    ]);
    res.forEach( e => {valid = valid && e.value});
    return valid;
  }

  setError = async (field, error) => {
    let errors = this.state.errors;
    errors[field] = error;
    this.setState({ errors });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (await this.validateSubmition()) {
      api.updateAccount(
        {
          password: this.state.fields["new password"], 
          email:  this.state.fields["email"]
        }).then( res => {
        alert("Account updated");
        this.props.closeForm();
      })
    }
  }
  
  handleChange = async (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleChangeCheck = async () => {
    let fields = this.state.fields;
    fields["admin"] = !fields["admin"];
    this.setState({ fields });
  }

  render() {
    return (
      <>
      <form className='acc-form' onSubmit={this.handleSubmit}>
        <label>Enter Current Password:</label>
        <input 
          className="form-control"
          type='password' 
          placeholder="" 
          value={this.state.fields["current password"]} 
          onChange={this.handleChange.bind(this, "current password")}
        />
        <span className="form-error">
          {this.state.errors["current password"]}
        </span>
        <h3>Update Email</h3>
        <label>Email:</label>
        <input 
          className="form-control"
          type='text' 
          placeholder="" 
          value={this.state.fields["email"]} 
          onChange={this.handleChange.bind(this, "email")}
        />
        <span className="form-error">
          {this.state.errors["email"]}
        </span>
        <label>Confirm Email:</label>
        <input 
          className="form-control"
          type='text' 
          placeholder="" 
          value={this.state.fields["confirm email"]} 
          onChange={this.handleChange.bind(this, "confirm email")}
        />
        <span className="form-error">
          {this.state.errors["confirm email"]}
        </span>
        <h3>Update Password</h3>
        <label>New Password:</label>
        <input 
          className="form-control"
          type='password' 
          placeholder="" 
          value={this.state.fields["new password"]} 
          onChange={this.handleChange.bind(this, "new password")}
        />
        <span className="form-error">
          {this.state.errors["new password"]}
        </span>
        <label>Confirm New Password:</label>
        <input 
          className="form-control"
          type='password' 
          placeholder="" 
          value={this.state.fields["confirm password"]} 
          onChange={this.handleChange.bind(this, "confirm password")}
        />
        <span className="form-error">
          {this.state.errors["confirm password"]}
        </span>
        <span className="form-error">
          {this.state.errors["form"]}
        </span>
        <button type='submit' value='Submit' className='btn btn-action'>Submit Changes</button>

      </form>
      
      </>
    )
  }
}

export default UpdateAccount