import { useState } from "react";
import { Navigate } from 'react-router-dom'

import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa"

import api from "../api";

const Login = ({ isAdmin, authed, setAuth, setIsAdmin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [passwordResetView, setpasswordResetView] = useState(false);

  function validateLogin(e) {
    e.preventDefault();
    if(!password || !email) {
      setLoginError("Please fill in both fields");
    } else {
      let submit = {
        username: email,
        password: password
      }
      api.login(submit).then(res => {
        if(res.data.success){
          setIsAdmin(res.data.isAdmin);
          setAuth(res.data.success);
        } else {
          setLoginError("Email or password was incorrect")
        }
      })
    }
  }
  
  function togglePasswordReset(e) {
    e.preventDefault(); 
    setLoginError("");
    setPassword("");
    setpasswordResetView(!passwordResetView);
  }

  function resetPassword(e) {
    e.preventDefault();
    if(!email) {
      setLoginError("Please enter an email address");
      return;
    }
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setLoginError("Please enter a valid email");
      return;
    }
    api.resetPassword({email: email}).then(res => {
      togglePasswordReset(e);
    }).catch(err => {
      setLoginError("Entered email is not associated with any accounts");
    });
  }

  return (   
    authed ? <Navigate to="/" replace /> :
    <div id="login">
      { !passwordResetView && <form className="login-form" onSubmit={validateLogin}>
        <img src={require("../images/pixlworks-logo.png")} alt="" height="90px" width="90px" style={{filter:'invert(20%)'}}/>
        <h2>Login to your account <br/> (email is case sensitive)</h2>
        <span className="login-error">{loginError}</span>
        <input 
          className="form-control"
          type='text' 
          placeholder="Email" 
          value={email} 
          onChange={e => setEmail(e.target.value)}
          style={{marginBottom: '5px'}}
        />
        <input 
          className="form-control"
          type='password' 
          placeholder="Password" 
          value={password} 
          onChange={e => setPassword(e.target.value)}
        />
        <button type='submit' value='Submit' className='btn btn-login'>
          Login
        </button>
        <a className="btn-reset" onClick={e => togglePasswordReset(e)}>
          Forgot Password?
        </a>  
      </form> }
      { passwordResetView && <form className="login-form" onSubmit={resetPassword}>
        <h2>Forgot your password?</h2>
        <h4>Enter your email below and a new password will be sent to you.</h4>
        <input 
          className="form-control"
          type='text' 
          placeholder="Email" 
          value={email} 
          onChange={e => setEmail(e.target.value)}
          style={{marginBottom: '5px'}}
        />
        <span className="form-error">
          {loginError}
        </span>
        <div className="action-group">
          <button type='submit' value='Submit' className="btn">
            Submit
            <FaArrowCircleRight style={ {marginLeft:'5px'} }/>
          </button>
          <button className="btn" onClick={e => togglePasswordReset(e)}>
            <FaArrowCircleLeft style={ {marginRight:'5px'} }/>
            Back
          </button>
        </div>
      </form> }
    </div>
  )
}

export default Login