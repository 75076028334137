import { NavLink, Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import { FaDownload, FaImages, FaArchive } from "react-icons/fa"
import { useState, useEffect, useMemo, useCallback } from "react"
import axios from "axios";


import { Table } from "../components"
axios.defaults.withCredentials = true;

const Files = (isAdmin) => {
  const navigate = useNavigate();
  const {encodedAddr} = useParams();
  console.log(encodedAddr, "cleaner: ", decodeURIComponent(encodedAddr) );

  console.log("isAdmin status: ", isAdmin.isAdmin)

  
  const [data, setData] = useState([]);


  async function pullData(){
    const response = await axios.post('http://fieldtech.pixlworks.com:8000/pullData', {encodedAddr} );
    console.log(response.data)
    //console.log(files)
    setData(response.data.data)
  }

  function cleanURl(url){
    var urlSend = url.split('[":5000/images/');
    var urlHalfClean = urlSend[1];
    var urlsend2 = urlHalfClean.split('"]');
    var urlClean = urlsend2[0];
    console.log(url, urlClean)
    return ":5000/images/" + urlClean
  }

  function getImages(images){
    //var data = '[":5000/images/1655301964339BC736219.jpg"]'
    if(images == null || images == ''){
      return;
    }
    images = images.replace('[', '');
    images = images.replace(']', '');
    images = images.replace(/"/g, '');
    images = images.split(",")
    console.log(images);
    //<img src= {"http://68.183.197.103" + cleanURl(entry.url_data)} style={{width: "40%", height:"40%"}}></img>
    //<br/>

    return images;
  }

  function deletefunction(id){
    navigate(`/deleteConfirmation/${id}`)
  }


  
  useEffect(() => {
    pullData()
    //setData(files);
  }, []);

  return (
    <div className="page-view">
      <h1>Notes for {encodedAddr}</h1>

      <ul>
        {data.map((entry)=>{
          return <li>
            <h2>Entry ID:  {entry.entryID} <br/> Note: {entry.note}</h2>

            {getImages(entry.url_data).map((image)=>{
                return <li>
                   <img src= {"http://68.183.197.103" + image} style={{width: "42%", height:"42%"}}></img>
                   <h1/>
              </li>
              })}

              {isAdmin.isAdmin && <button onClick={()=> deletefunction(entry.entryID)}>Delete Entry</button>}
  
            </li>
        })}
      </ul>

      <h1></h1>
      <h2></h2>



    </div>
  )
}

export default Files
