import React, { Component } from "react"

import api from "../api"

class AddAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: { 
        "firstname": "", 
        "lastname": "", 
        "email": "", 
        "confirm email": "",
        "admin": false
      },
      errors: {
        "firstname": "", 
        "lastname": "", 
        "email": "", 
        "confirm email": ""
      },
    };
  }

  validateName = async (name) => {
    let valid = true;
    let fields = this.state.fields;
    if (!fields[name]) {
      valid = false;
      let error = "Field cannot be empty";
      this.setError(name, error);
    }

    if (fields[name] !== "") {
      if (!fields[name].match(/^([A-Za-zÀ-ÖØ-öø-ÿ]+[ -]?[A-Za-zÀ-ÖØ-öø-ÿ]+)+$/)) {
        valid = false;
        let error = "Names can only consist of letters";
        this.setError(name, error);
      }
    }
    if (valid) {
      this.setError(name, "");
    }
    return valid;
  }

  validateEmail = async () => {
    let email = this.state.fields["email"];
    if (!email) {
      let error = "Field cannot be empty";
      this.setError("email", error);
      return false;
    }

    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      let error = "Please enter a valid email";
      this.setError("email", error);
      return false;
    }

    let res = await api.getAccountByEmail(email);
      if(res.data.success) {
        let error = "Email is already in use";
        this.setError("email", error);
        return false;
      } else {
        this.setError("email", "");
        return true;
      }
  }

  validateConfirmationEmail = async () => {
    let valid = true;
    let email = this.state.fields["email"];
    let conf = this.state.fields["confirm email"];
    if (!conf) {
      valid = false;
      let error = "Field cannot be empty";
      this.setError("confirm email", error);
    }

    if (conf !== "") {
      if (conf !== email) {
        valid = false;
        let error = "Emails do not match";
        this.setError("confirm email", error);
      }
    }
    if (valid) {
      this.setError("confirm email", "");
    }
    return valid;
  }

  validateSubmition = async (e) => {
    let res = await Promise.allSettled([
      this.validateName("firstname"),
      this.validateName("lastname"),
      this.validateEmail(),
      this.validateConfirmationEmail()
    ]);
    let valid = true;
    res.forEach( e => {valid = valid && e.value});
    return valid;
  }

  setError = async (field, error) => {
    let errors = this.state.errors;
    errors[field] = error;
    this.setState({ errors });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (await this.validateSubmition()) {
      await api.insertAccount(this.state.fields).then( res => {
        console.log("account created");
        this.props.setData([res.data.account, ...this.props.data]);
        this.props.closeForm();
      });
    }
  }
  
  handleChange = async (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleChangeCheck = async () => {
    let fields = this.state.fields;
    fields["admin"] = !fields["admin"];
    this.setState({ fields });
  }

  render() {
    return (
      <form className='acc-form' onSubmit={this.handleSubmit}>
        <label>First name:</label>
        <input 
          className="form-control"
          type='text' 
          placeholder="" 
          value={this.state.fields["firstname"]} 
          onChange={this.handleChange.bind(this, "firstname")}
        />
        <span className="form-error">
          {this.state.errors["firstname"]}
        </span>
        <label>Last name:</label>
        <input 
          className="form-control"
          type='text' 
          placeholder="" 
          value={this.state.fields["lastname"]} 
          onChange={this.handleChange.bind(this, "lastname")}
        />
        <span className="form-error">
          {this.state.errors["lastname"]}
        </span>
        <label>Email:</label>
        <input 
          className="form-control"
          type='text' 
          placeholder="" 
          value={this.state.fields["email"]} 
          onChange={this.handleChange.bind(this, "email")}
        />
        <span className="form-error">
          {this.state.errors["email"]}
        </span>
        <label>Confirm Email:</label>
        <input 
          className="form-control"
          type='text' 
          placeholder="" 
          value={this.state.fields["confirm email"]} 
          onChange={this.handleChange.bind(this, "confirm email")}
        />
        <span className="form-error">
          {this.state.errors["confirm email"]}
        </span>
        <span style={{whiteSpace: "nowrap"}}>
          <label>Give account admin privileges:</label>
          <input
            type='checkbox'
            value={this.state.fields["admin"]}
            onChange={this.handleChangeCheck.bind(this)}
          />
        </span>
        <button type='submit' value='Submit' className='btn btn-action'>Submit</button>
      </form>
    )
  }
}

export default AddAccount