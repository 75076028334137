import { Navigate } from 'react-router-dom'

const RequireAdmin = ({ children, authed, admin }) => {
  return authed === true ? (admin === true ? children : 
    <div className='page-view'>
      <h2> 
        401 you are not permitted to view this resource 
      </h2>
    </div>) : <Navigate to="/login" replace />;
}

export default RequireAdmin