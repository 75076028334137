import { useState } from "react"
import { NavLink, Navigate } from 'react-router-dom'
import { FaUserAlt, FaAngleDown, FaUserEdit, FaSignOutAlt } from "react-icons/fa"
import api from '../api'
import Modal from 'react-modal';

import UpdateAccount from "./UpdateAccount";


const Header = ( {isAdmin, setIsAdmin, setAuth}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleLogout = e => {
    api.logout().then( res => {
      setIsAdmin(false);
      setAuth(false);
      return <Navigate to="/login" replace />
    })
  }

  return (
    <div id='page-header'>
      <img src={require("../images/pixlworks-logo.png")} height="45px" width="45px" style={{filter:'invert(70%)', float:'left'}}/> 
      <span className='header-title'>PixlWorks</span>
      <div id='navbar'>
        <div className='navbar nav-menu'>
          <ul>
            <li>
              <NavLink to='/' className={({ isActive }) => isActive? "active": ''}>Notes</NavLink>
            </li>
            <li>
              {isAdmin && <NavLink to='/admin' className={({ isActive }) => isActive? "active": ''}>Admin</NavLink>}
            </li>
          </ul>
        </div>
        <div className='user'>
          <div className='user-dropdown'>
            <div className='user-dropdown-toggle'>
              <FaUserAlt className='fa-user'/>
              <span>Account</span>
              <FaAngleDown className='fa-angle-down'/>
            </div>
            <div className='user-dropdown-content'>
                <ul>
                  <li onClick={openModal}>
                      <FaUserEdit className='fa-inline-menu'/>
                      <span>Account details</span>
                  </li>
                  <li onClick={e => handleLogout(e)}>
                      <FaSignOutAlt className='fa-inline-menu'/>
                      <span>Log out</span>
                  </li>
                </ul>
            </div>  
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="Modal"
        contentLabel="Manage Account"
      >
        <h2>Manage Account</h2>
        <UpdateAccount
          closeForm={closeModal}
        />
      </Modal>
    </div>    
  )
}

export default Header


