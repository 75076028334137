import axios from 'axios'

const accountApi = axios.create({
  withCredentials: true,
  baseURL: 'http://fieldtech.pixlworks.com:8000/account',
});

export const insertAccount = async payload => accountApi.post(`/account`, payload);
export const getAllAccounts = async () => accountApi.get(`/accounts`);
export const updateAccount = async (payload) => accountApi.put(`/account`, payload);
export const resetPassword = async (payload) => accountApi.post(`/account-reset`, payload)
export const deleteAccountById = async id => accountApi.delete(`/account/${id}`);
export const getAccountByEmail = async email => accountApi.get(`/account/${email}`);
export const verifyPassword = async (payload) => accountApi.post(`verify-password`, payload);

const authApi = axios.create({
  withCredentials: true,
  baseURL: 'http://fieldtech.pixlworks.com:8000/auth',
});

export const login = async payload => authApi.post(`/login`, payload);
export const logout = async () => authApi.get(`/logout`);
export const checkAuth = async () => authApi.get(`/isauth`);

const apis = {
  insertAccount,
  getAllAccounts,
  updateAccount,
  resetPassword,
  deleteAccountById,
  getAccountByEmail,
  login,
  logout,
  checkAuth,
  verifyPassword
};

export default apis;