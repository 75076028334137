import { FaTrash, FaUserPlus } from "react-icons/fa"
import { useState, useEffect, useMemo, useCallback } from "react"
import Modal from 'react-modal';

import { AddAccount, Table } from "../components"
import { SelectColumnFilter } from "../components/Table"
import api from "../api"

Modal.setAppElement('#root');

const Admin = ( ) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleDelete = useCallback(e => {
    api.deleteAccountById(e._id).then(res => {
      if  (!res.data.success) {
        alert(res.data.error);
      } else {
        setData(data.filter(account => { 
          return account !== e;
      }));
      }
    })
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstname",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Last Name",
        accessor: "lastname",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Admin",
        id: "admin",
        accessor: data => data.admin.toString(),
        canFilter: true, 
        Filter: SelectColumnFilter,
        filter:'includes',
        disableSortBy: true,
        disableGlobalFilter: true,      
      },
      {
        accessor: 'action',
        Cell: row => (
          <div className="btn-group">
            <button className="btn btn-action btn-red" onClick={e => handleDelete(row.row.original)}>
              <FaTrash className="fa-btn"/>
              <span> Delete Profile</span>
            </button>
          </div>
        ),
        disableGlobalFilter: true,
        sortable: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
      }
    ],
    [handleDelete]
  );

  useEffect(() => {
    api.getAllAccounts().then(res => {
      setData(res.data.data);
    });
  }, []);

  return (
    <div className="page-view">
      <div className="row">
        <span className="title">Manage Accounts</span>
        <div className="btn-group">
          <button className="btn btn-action" 
            style={{
              margin: '0px',
            }}
            onClick={openModal}>
            <FaUserPlus className="fa-btn"/>
            <span>Create Account</span>
          </button>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="Modal"
        contentLabel="Add Accounts"
      >
        <h2>Create Account</h2>
        <AddAccount
          setData={setData}
          data={data}
          closeForm={closeModal}
        />
      </Modal>
      <Table columns={columns} data={data} type={'accounts'}/>
    </div>
  )
}

export default Admin
