import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useState, useEffect } from 'react';

import { RequireAuth, RequireAdmin, NotFound } from './components';
import { Files, Admin, Login, ViewNote, ArchivedFiles, DeleteConfirmation } from "./pages";
import { Header } from "./components";
import api from "./api"

//use effect check if valid session
function App() {
  const [authed, setAuthed] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [stateIsSet, setStateIsSet] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    api.checkAuth().then(res => {
      setAuthed(res.data.success);
      setIsAdmin(res.data.isAdmin);
      setStateIsSet(true);
    }).catch(err => {
      setError("404 Network Error");
    });
  }, []);

  return (
    !stateIsSet ? <h2>{error}</h2> :
    <Router>
      { authed && <Header isAdmin={isAdmin} setAuth={setAuthed} setIsAdmin={setIsAdmin}/> }   
      <Routes>
        <Route path='/login' exact element={
          <Login isAdmin={isAdmin} authed={authed} setAuth={setAuthed} setIsAdmin={setIsAdmin}/>
        }/>
        <Route path ='/admin' exact element={
          <RequireAdmin authed={authed} admin={isAdmin}>
            <Admin />
          </RequireAdmin>
        }/>
        <Route path ='/viewNote/:encodedAddr' exact element={
          <RequireAuth authed={authed}>
            <ViewNote isAdmin={isAdmin}/>
          </RequireAuth>
        }/>
        <Route path ='/deleteConfirmation/:id' exact element={
          <RequireAuth authed={authed}>
            <DeleteConfirmation />
          </RequireAuth>
        }/>
        <Route path ='/' exact element={
          <RequireAuth authed={authed}>
            <Files />
          </RequireAuth>
        }/>
        <Route path ='/archived' exact element={
          <RequireAuth authed={authed}>
            <ArchivedFiles />
          </RequireAuth>
        }/>
        <Route path='*' exact element={
          <RequireAuth authed={authed}>
            <NotFound/>
          </RequireAuth>
        }/>   
      </Routes>
    </Router>
  );
}

export default App;