import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom'
import { FaDownload, FaImages, FaArchive } from "react-icons/fa"
import { useState, useEffect, useMemo, useCallback } from "react"
import axios from "axios";



import { Table } from "../components"
axios.defaults.withCredentials = true;

const Files = () => {
  const navigate = useNavigate();

  const files = [
    {id: 1, text: 'example 1', add: "lolk"},
    {id: 2, text: 'example 2', add: "ok"},
    {id: 3, text: 'example 3', add: "ok"},
    {id: 4, text: 'example 4', add: "ok"},
    {id: 5, text: 'example 5', add: "ok"}
  ];
  
  const [data, setData] = useState([]);

  const handleArchive = useCallback(e => {
    console.log("archiving: ", e.location)
    const response = axios.post('http://fieldtech.pixlworks.com:8000/archiveEntryWebApp', {encodedAddr: e.location});

    setData(data.filter(file => { 
      return file !== e;
    }));
  }, [data]);

  const handleViewImages = e => {
    console.log('view placeholder', e);
    /*var urlSend = e.url_data.split('[":5000/images/');
    var urlHalfClean = urlSend[1];
    var urlsend2 = urlHalfClean.split('"]');
    var urlClean = urlsend2[0];
    console.log(e.url_data, urlClean);*/
    console.log("before encode", e.location)
    var encodedAddr = encodeURIComponent(e.location)
    console.log("after encode", encodedAddr)
    navigate(`/viewNote/${encodedAddr}`)



  }

  const handleDownload = e => {
    console.log('download placeholder')
  }

  const columns = useMemo(
    () => [
      {
        Header: "Address",
        accessor: "location",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Date",
        accessor: "startDate",
        filterable: false,
        disableFilters: true,
      },
      {
        accessor: 'action',
        Cell: row => (
          <div className="btn-group">
            <button className="btn btn-action" onClick={e => handleViewImages(row.row.original)}>
              <FaImages className="fa-btn"/>
              <span>View Images</span>
            </button>
            <button className="btn btn-action" onClick={e => handleArchive(row.row.original)}>
              <FaArchive className="fa-btn"/>
              <span>Archive</span>
            </button>
          </div>
        ),
        disableGlobalFilter: true,
        sortable: false,
        filterable: false,
        disableFilters: true,
        disableSortBy: true,
      }
    ],
    [handleViewImages, handleArchive, handleDownload]
  );

  async function pullEntries(){
    const response = await axios.get('http://fieldtech.pixlworks.com:8000/pullEntriesTODAY');
    console.log(response.data.data)
    //console.log(files)
    setData(response.data.data)
  }

  
  useEffect(() => {
    pullEntries()
    //setData(files);
  }, []);

  return (
    <div className="page-view">
      <div className="row">
        <span className="title">Current Sites</span>
        <div className='btn-group'>
          <button className="btn btn-action" style={{marginLeft: "0px"}}>Active sites</button> 
          <button className="btn btn-action" onClick={()=> navigate('/archived')} style={{marginRight: "0px"}}>Archived sites</button>
        </div>
      </div>
      <Table 
        columns={columns} 
        data={data} 
        type={'files'} 
        />
    </div>
  )
}

export default Files
